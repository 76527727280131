import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

export default function About() {
  return (
    <Layout>
      <Seo title="About" />
      <div className="max-w-3xl">
        <h1 className="col-span-6 text-center">About</h1>
        <div className="flex-none gap-4 md:flex">
          <div className="flex flex-1 md:flex-none justify-center">
            <div>
              <img className="rounded-md" src="/uploads/thumbnail_profile_picture_ce703ceaa6.jpg" />
            </div>
          </div>
          <div className="flex-1 blogContent">
            <p>
              My name is Nic Schlueter. I am a software developer. I have been helping companies build great software since 2000.
            </p>
            <p>
              In theory, this is a place I will write down random thoughts. I tend to focus on tech and science. But I also enjoy travel, cooking and music.
            </p>
            <p>
              I currently hanging out in Coimbra, Portugal. I am always up for meeting new people, send me an <a href="mailto:schlueter@gmail.com">email</a> if you have any thoughts.
            </p>
            <p>
              I can also be found on Mastodon, <a href="https://mastodon.cloud/@schlu">@schlu</a>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
